"use client";

import * as React from "react";

import { cn } from "@/lib/utils";

import { Input } from "./ui/input";

export const PasswordInput = ({ ...props }: React.ComponentProps<typeof Input>) => {
	const [hide, setHide] = React.useState(false);

	return (
		<div className={cn("relative h-11 w-full")}>
			<Input type={hide ? "text" : "password"} {...props} />

			<span
				onClick={() => setHide((value) => !value)}
				className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-xs font-bold uppercase tracking-wider text-examine-purple-600"
			>
				{hide ? "hide" : "show"}
			</span>
		</div>
	);
};

PasswordInput.displayName = "PasswordInput";
