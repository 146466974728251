import { fetchWrapper } from "@lib/hooks/fetch-client";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { ChevronRightIcon } from "lucide-react";
import React, { useMemo, useState } from "react";

import { cn } from "@/lib/utils";
import { Library } from "@/types";

import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Input } from "./ui/input";
import Link from "./ui/link";

type LibraryAuthProps = {
	children: React.ReactNode;
	triggerClassName?: string;
};

const classNames = {
	search: {
		modal: `md:w-1/2 h-1/2 max-h-1/2`,
		input: `w-full placeholder:text-gray-600`,
		formParent: `p-5 w-full`,
	},
	results: {
		container: `mt-5`,
		item: `p-5 flex justify-between items-center border-b border-gray-200 text-default hover:no-underline`,
		empty: `flex flex-col items-center justify-end font-medium text-lg grow-0`,
		emptyCta: `hover:underline text-examine-purple-400`,
	},
};

const fetchLibraries = async (): Promise<{ data: Library[] }> => {
	return await fetchWrapper<{ data: Library[] }>(`/v1/libraries`);
};

export default function LibraryAuthSelect(props: LibraryAuthProps) {
	const [searchResults, setSearchResults] = useState<Library[]>(null);
	const [libraries, setLibraries] = useState<Library[]>([]);

	const searchLibrary = (value: string) => {
		if (value) {
			setSearchResults(
				libraries
					.filter((library) => library.name.toLowerCase().includes(value.toLowerCase()))
					.slice(0, 3)
			);
		} else {
			setSearchResults([]);
		}
	};
	const loadLibraries = async () => {
		const libs = await fetchLibraries();
		setLibraries(libs.data);
	};
	const onOpen = () => {
		if (!libraries.length) {
			loadLibraries();
		}
		setSearchResults([]);
	};
	return (
		<div>
			<Dialog onOpenChange={onOpen}>
				<DialogTrigger className={props.triggerClassName}>{props.children}</DialogTrigger>
				<VisuallyHidden>
					<DialogTitle></DialogTitle>
				</VisuallyHidden>
				<DialogContent className={cn("library-form-modal", classNames.search.modal)}>
					<>
						<div className={cn("library-form", classNames.search.formParent)}>
							<Input
								className={cn("library-search-input", classNames.search.input)}
								placeholder="Search for your library"
								type="text"
								onChange={(e) => searchLibrary(e.target.value)}
							/>
							<div className={cn("search-results", classNames.results.container)}>
								{searchResults !== null &&
									searchResults.map((result) => (
										<Link
											key={result.id}
											href={`${process.env.NEXT_PUBLIC_API_URL}/auth/sip2?library=${result.id}`}
											className={cn("library-search-result", classNames.results.item)}
										>
											<div>{result.name}</div>
											<div>
												<ChevronRightIcon />
											</div>
										</Link>
									))}
							</div>
						</div>
						<div className={cn("empty-state", classNames.results.empty)}>
							Can&apos;t find your library?
							<a
								className={classNames.results.emptyCta}
								href="https://examine.com/plus/public-libraries/?utm_source=examine-library-auth"
							>
								Ask your local library to sign up for Examine+
							</a>
						</div>
					</>
				</DialogContent>
			</Dialog>
		</div>
	);
}
