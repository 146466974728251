import { format } from "date-fns";

const startDate = new Date("2025-03-02T12:00:00-04:00");
const endDate = (formatString: string = "MMMM d") =>
	format(new Date("2025-03-17T07:00:00"), formatString);

const percentOff: string = "27%";
const upsellPercentOff: string = "30%";
const salesPrices: { [key: string]: number } = {
	monthly: 12,
	yearly: 144,
	lifetime: 699,
};

const saleVariant: string = "anniversary";
const saleLabel: string = `${saleVariant} sale`;
const salesId: string = "anniversary";
const salesYear: string = new Date().getFullYear().toString();

export {
	startDate,
	endDate,
	percentOff,
	upsellPercentOff,
	saleVariant,
	saleLabel,
	salesId,
	salesYear,
	salesPrices,
};
