import * as React from "react";

import { cn } from "@/lib/utils";

const Input = ({ className, type, ...props }: React.ComponentProps<"input">) => {
	return (
		<input
			type={type}
			className={cn(
				"w-full rounded-md border-gray-400 hover:shadow-input-hover focus:border-primary focus:shadow-sm focus:outline-none focus:ring-0",
				className
			)}
			{...props}
		/>
	);
};
Input.displayName = "Input";

export { Input };
