"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { captureException } from "@sentry/nextjs";
import { deleteCookie, getCookie, hasCookie, setCookie } from "cookies-next/client";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import * as z from "zod";

import { Loading } from "@/components/icons/loading";
import {
	GoogleLoginButton,
	LibraryLoginButton,
	OpenAthensLoginButton,
} from "@/components/social-login-button";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ToastAction } from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { trackSubmitSurveyEvent } from "@/lib/analytics-tracking";
import { useAuth } from "@/lib/hooks/auth";
import { fetchWrapper } from "@/lib/hooks/fetch-client";
import { cn } from "@/lib/utils";
import { userAuthSchema } from "@/lib/validations/auth";
import { SurveyData } from "@/types/index";

import { Icons } from "./icons";
import { PasswordInput } from "./password-input";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

type FormData = z.infer<typeof userAuthSchema>;

const API_URL = process.env.NEXT_PUBLIC_API_URL;

const collectSurveyData = async () => {
	const surveyCollected = localStorage.getItem("surveyCollected");
	if (surveyCollected) {
		return;
	}

	try {
		const response: { taken: boolean; data: SurveyData } = await fetchWrapper(
			"/v1/members/survey?product_type=member&data=0"
		);

		if (response.taken) {
			trackSubmitSurveyEvent(response.data);
			localStorage.setItem("surveyCollected", "true");
		}
	} catch (error) {
		captureException(error);
	}
};

export function UserAuthForm({ className }: UserAuthFormProps) {
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
	} = useForm<FormData>({
		resolver: zodResolver(userAuthSchema),
	});

	const [isAuthenticating, setIsAuthenticating] = React.useState<boolean>(false);
	const verified = React.useRef(false);

	const searchParams = useSearchParams();
	const code = searchParams.has("code") ? searchParams.get("code") : null;
	const auth = searchParams.has("auth") ? searchParams.get("auth") : null;
	const router = useRouter();
	const { toast } = useToast();
	const { login, addToOrganization } = useAuth({
		redirectIfAuthenticated: searchParams.get("return_url"),
	});
	const [status, setStatus] = React.useState<string | null>(null);
	const [error, setErrors] = React.useState<string | null>(null);
	const { mutate } = useSWRConfig();

	React.useEffect(() => {
		async function verifyGoogleAuth() {
			setIsAuthenticating(true);
			verified.current = true;

			try {
				const params: any = {};
				params["code"] = searchParams.get("gcode");
				params["scope"] = searchParams.get("scope");
				params["authuser"] = searchParams.get("authuser");
				params["prompt"] = searchParams.get("prompt");

				const response = await fetch(
					API_URL + "/auth/google/callback?" + new URLSearchParams(params).toString(),
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				if (!response.ok) {
					throw new Error("Login failed. Please try again");
				}

				const signInResult = await response.json();

				if (!signInResult.data) {
					throw new Error("Login failed. Please try again");
				}

				const companyCode = hasCookie("companyCode") ? getCookie("companyCode") : null;
				const token = signInResult.data.token;

				setCookie("token", token, {
					path: "/",
					maxAge: 60 * 60 * 24 * 365,
				});

				if (!searchParams.has("code") && !companyCode) {
					toast({
						title: "Login Successful.",
					});
				}

				if (companyCode && token) {
					try {
						await addToOrganization(token, companyCode as string);
						deleteCookie("company-code");
						toast({
							title: "Login Successful.",
							variant: "success",
						});
					} catch (err) {
						toast({
							title: "Login Successful",
							description: "We logged you in, but couldn't assign your company",
							variant: "warning",
						});
					}
				} else {
					toast({
						title: "Login Successful",
						description: "You are now logged in.",
						variant: "success",
					});
				}

				const prevPath =
					window?.sessionStorage.getItem("prevPath") === "/signup/"
						? "/"
						: window?.sessionStorage.getItem("prevPath");
				const returnUrl = searchParams.has("returnUrl") ? searchParams.get("returnUrl") : null;
				const path = returnUrl || prevPath || "/";

				await collectSurveyData();
				router.replace(path);
				router.refresh();
			} catch (error) {
				if (error instanceof Error) {
					toast({
						title: "Error!",
						description: error.message,
						action: <ToastAction altText="Try again">Try again</ToastAction>,
						variant: "error",
					});
				}
			} finally {
				setIsAuthenticating(false);
			}
		}

		if (
			searchParams.has("gcode") &&
			searchParams.has("scope") &&
			searchParams.has("authuser") &&
			searchParams.has("prompt") &&
			!verified.current
		) {
			verifyGoogleAuth();
		}
	}, [router, searchParams, toast, verified, addToOrganization]);

	React.useEffect(() => {
		async function verifyReferrerAuth() {
			setIsAuthenticating(true);
			try {
				const params: any = {};
				params["code"] = searchParams.get("auth_code");
				params["state"] = searchParams.get("state");

				const response = await fetch(
					API_URL + "/auth/referrer/callback?" + new URLSearchParams(params).toString(),
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				if (!response.ok) {
					throw new Error("Login failed. Please try again");
				}

				const signInResult = await response.json();

				if (!signInResult.data) {
					throw new Error("Login failed. Please try again");
				}

				setCookie("token", signInResult.data.token, {
					path: "/",
					maxAge: 60 * 60 * 24 * 365,
				});

				toast({
					title: "Login Successful.",
				});

				await collectSurveyData();
				router.replace("/");
				router.refresh();
			} catch (error) {
				if (error instanceof Error) {
					toast({
						title: "Error!",
						description: error.message,
						action: <ToastAction altText="Try again">Try again</ToastAction>,
						variant: "error",
					});
				}
			} finally {
				setIsAuthenticating(false);
			}
		}

		if (searchParams.has("auth_code") && searchParams.has("state")) {
			verifyReferrerAuth();
		}
	}, [router, searchParams, toast]);

	async function onSubmit(data: FormData) {
		const res = await login({
			...data,
			code,
			auth,
			setErrors,
			setStatus,
		});

		if (res?.token) {
			await collectSurveyData();
		}
	}

	return (
		<form className={cn("space-y-4 lg:space-y-6", className)} onSubmit={handleSubmit(onSubmit)}>
			<div className="mb-4 mt-8 flex w-full flex-col justify-center gap-4">
				<GoogleLoginButton
					clientID={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
					loginURI={`${process.env.NEXT_PUBLIC_API_URL}/auth/google`}
					context="signin"
					buttonText="Log in with Google"
				/>
				{code ? null : <OpenAthensLoginButton oaAppId={process.env.NEXT_PUBLIC_OA_APP_ID} />}
				<LibraryLoginButton />
			</div>
			<div className="my-5 flex w-full items-center">
				<div className="grow border-t border-[#EDF2F7]" />
				<span className="mx-6 shrink text-xs text-gray-600">OR</span>
				<div className="grow border-t border-[#EDF2F7]" />
			</div>
			<div className="space-y-5">
				<div className="relative">
					<Input
						id="email"
						type="email"
						autoCapitalize="none"
						autoComplete="email"
						autoCorrect="off"
						className="peer h-11 w-full placeholder:text-gray-600"
						placeholder="Email"
						disabled={isSubmitting || isAuthenticating}
						{...register("email")}
						required
					/>
					{errors?.email && (
						<p className="flex space-x-1 pt-1.5 text-xs font-medium text-red-400">
							<Icons.warning className="size-4" strokeWidth={1.5} />
							<span className="inline basis-11/12">
								Please enter an email address. If you can&apos;t remember the email you used to sign
								up for Examine, please{" "}
								<Link href="/contact" className="inline text-purple-500 underline hover:opacity-75">
									contact us
								</Link>
							</span>
						</p>
					)}
				</div>
				<div className="relative">
					<PasswordInput
						id="password"
						autoComplete="current-password"
						className="peer h-11 w-full placeholder:text-gray-600"
						placeholder="Password"
						disabled={isSubmitting || isAuthenticating}
						{...register("password")}
						required
					/>
					{errors?.password ? (
						<p className="px-1 text-xs text-red-600">{errors.password.message}</p>
					) : null}
				</div>
				<Button
					disabled={isSubmitting}
					type="submit"
					className="block h-11 w-full justify-center md:h-12"
				>
					{isSubmitting ? <Loading className="text-inherit" /> : "Login"}
				</Button>
			</div>
		</form>
	);
}
