"use client";

import { setCookie } from "cookies-next/client";
import { BookOpenIcon } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import Script from "next/script";
import React from "react";

import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

import LibraryAuthSelect from "./library-auth-select";

interface OpenAthensLoginButtonProps {
	oaAppId: string;
}

interface GoogleLoginButtonProps {
	loginURI: string;
	clientID?: string;
	context?: "signup" | "signin";
	buttonText?: "Sign up with Google" | "Log in with Google";
}
interface LibraryLoginButtonProps {
	buttonText?: "Log in with your library card";
}

const GoogleIcon = () => (
	<svg viewBox="0 0 48 48" className="block size-[22px]">
		<path
			fill="#EA4335"
			d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
		></path>
		<path
			fill="#4285F4"
			d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
		></path>
		<path
			fill="#FBBC05"
			d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
		></path>
		<path
			fill="#34A853"
			d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
		></path>
		<path fill="none" d="M0 0h48v48H0z"></path>
	</svg>
);

const classNames = {
	authButton: {
		button: `w-full shadow-[0px_0px_1px_rgba(12,26,75,0.2),0px_1px_3px_rgba(50,50,71,0.1)] hover:opacity-75 active:shadow-inner
    flex items-center justify-center gap-3 h-11 text-gray-900 rounded-md bg-white focus:outline-none focus:ring-2
    focus:ring-primary focus:ring-opacity-50 transition-all duration-200 ease-in-out`,
		icon: "h-6 w-6",
	},
};

function OpenAthensLoginButton({ oaAppId }: OpenAthensLoginButtonProps) {
	const html = `
    (function(w, a, y, f) {
      w._wayfinder = w._wayfinder || function() {
        (w._wayfinder.q = w._wayfinder.q || []).push(arguments);
      };

      p = { oaDomain: 'examine.com', oaAppId: '${oaAppId}' };
      w._wayfinder.settings = p;

      h = a.getElementsByTagName('head')[0];
      s = a.createElement('script');
      s.referrerPolicy = 'origin';
      q = Object.keys(p).map(function(key) { return key + '=' + p[key] }).join('&');
      s.src = y + 'v1' + f + "?" + q;
      h.appendChild(s);
    })(window, document, 'https://wayfinder.openathens.net/embed/', '/loader.js');

    _wayfinder.options = {
      rememberCrossSite: false
    };
  `;

	return (
		<>
			<link rel="preconnect" href="https://wayfinder.openathens.net" />
			<Script id="wayfinder-script" dangerouslySetInnerHTML={{ __html: html }} />
			<Button
				className={cn("wayfinder-login", classNames.authButton.button)}
				type="button"
				variant="text"
			>
				<Image
					src="/images/OpenAthens-purple-icon.png"
					alt="OpenAthens logo"
					width="22"
					height="22"
				/>
				<span>Access through your institution</span>
			</Button>
		</>
	);
}

function GoogleLoginButton({
	loginURI,
	buttonText = "Log in with Google",
}: GoogleLoginButtonProps) {
	const searchParams = useSearchParams();
	const companyCode = searchParams.has("code") ? searchParams.get("code") : "";

	React.useEffect(() => {
		if (companyCode) {
			setCookie("companyCode", companyCode, {
				maxAge: 3600,
			});
		}
	}, [companyCode]);

	return (
		<Link className={cn(classNames.authButton.button, "relative")} type="button" href={loginURI}>
			<GoogleIcon />
			<span>{buttonText}</span>
		</Link>
	);
}
function LibraryLoginButton({
	buttonText = "Log in with your library card",
}: LibraryLoginButtonProps) {
	return (
		<LibraryAuthSelect triggerClassName={cn("library-login", classNames.authButton.button)}>
			<div className={cn("library-login", classNames.authButton.button)}>
				<BookOpenIcon className={classNames.authButton.icon} />
				<span>{buttonText}</span>
			</div>
		</LibraryAuthSelect>
	);
}

export { OpenAthensLoginButton, GoogleLoginButton, LibraryLoginButton };
