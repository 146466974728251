import {
	AlertTriangle,
	ArrowRight,
	Check,
	ChevronLeft,
	ChevronRight,
	Circle,
	File,
	FileText,
	HelpCircle,
	Image,
	Loader2,
	type LucideIcon,
	MoreVertical,
	Plus,
	Settings,
	Trash,
	X,
} from "lucide-react";
import { IoCheckmarkCircle } from "react-icons/io5";

import User from "@/components/icons/user";

export type Icon = LucideIcon;

export const Icons = {
	close: X,
	spinner: Loader2,
	chevronLeft: ChevronLeft,
	chevronRight: ChevronRight,
	trash: Trash,
	post: FileText,
	page: File,
	media: Image,
	settings: Settings,
	ellipsis: MoreVertical,
	add: Plus,
	warning: AlertTriangle,
	user: User,
	arrowRight: ArrowRight,
	help: HelpCircle,
	check: Check,
	Circle: Circle,
	CircleCheck: IoCheckmarkCircle,
};
